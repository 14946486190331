import Vue from 'vue'
import Vuex from 'vuex'
import UsersResource from "@/resources/UsersResource";
import {vm} from '@/main'
import notify from "@/components/notifications/notify";
import PizzeriasResource from "@/resources/PizzeriasResource";
import CategoriesResource from "@/resources/CategoriesResource";
import ContractorsResource from "@/resources/ContractorsResource";

Vue.use(Vuex)

export default new Vuex.Store({
    namespaced: true,
    state: {
        authenticated: false,
        user: null,
        loading: false,
        userSurveyDialog: false,
        usersList: localStorage.getItem('usersList') ? JSON.parse(localStorage.getItem('usersList')) : null,
        categoriesList: localStorage.getItem('categoriesList') ? JSON.parse(localStorage.getItem('categoriesList')) : null,
        pizzeriasList: localStorage.getItem('pizzeriasList') ? JSON.parse(localStorage.getItem('pizzeriasList')) : null,
        lastUpdated: localStorage.getItem('lastUpdated') ? JSON.parse(localStorage.getItem('lastUpdated')) : null,
        groupsList: localStorage.getItem('groupsList') ? JSON.parse(localStorage.getItem('groupsList')) : null,
        rolesList: localStorage.getItem('rolesList') ? JSON.parse(localStorage.getItem('rolesList')) : null,
        contractorsList: localStorage.getItem('contractorsList') ? JSON.parse(localStorage.getItem('contractorsList')) : null,
    },
    getters: {
        authenticated(state) {
            return state.authenticated
        },
        user(state) {
            return state.user
        },
        userSurveyDialog(state) {
            return state.userSurveyDialog
        },
        isLoading(state) {
            return state.loading
        },
        isAdmin(state) {
            if (state.user.groups.find(grp => grp.id === 3)) {
                return true
            }
            return false
        },
        isFinance(state) {
            if (state.user.roles.find(grp => grp.id === 29)) {
                return true
            }
            return false
        },
        isBoardDirector(state) {
            if (state.user.roles.find(grp => grp.id === 27)) {
                return true
            }
            return false
        },
        isRegionalManager(state) {
            if (state.user.roles.find(role => role.id === 20)) {
                return true
            }
            return false
        },
        isUpr(state) {
            if (state.user.roles.find(role => role.id === 3)) {
                return true
            }
            return false
        },
        isManager(state) {
            if (state.user.roles.find(role => role.id === 4)) {
                return true
            }
            return false
        },
        canSeeCash(state) {
            if (state.user.roles.find(role =>
                role.id === 12 // Управление сетью
                ||
                role.id === 10 // Бухгалтер
                ||
                role.id === 20 // Территориальный управляющий
                ||
                role.id === 16 // Ревизор
                ||
                role.id === 3 // Управляющий
            ))
                return true
            return false
        },
        canCashStat(state) {
            if (state.user.roles.find(role =>
                role.id === 3 // Управляющий
                ||
                role.id === 12 // Управление сетью
                ||
                role.id === 1 // Ставрополь бухгалтер
                ||
                role.id === 10 // Бухгалтер
                ||
                role.id === 8 // Абинск бухгалтер
                ||
                role.id === 16 // Ревизор
                ||
                role.id === 20 // Территориальный управляющий
            ))
                return true
            return false
        },
        canSeeUsers(state) {
            if (state.user.roles.find(role =>
                role.id === 10 // Бухгалтер
                ||
                role.id === 3 // Управляющий
            ))
                return true
            return false
        },
        canSeeAccounterKitchenSalary(state) {
            if (state.user.roles.find(role =>
                role.id === 10 // Бухгалтер
            ))
                return true
            return false
        },
        canCashReport(state) {
            if (state.user.roles.find(role =>
                role.id === 3 // Управляющий
                ||
                role.id === 4 // Кассир
                ||
                role.id === 12 // Управление сетью
                ||
                role.id === 8 // Абинск бухгалтер
                ||
                role.id === 16 // Ревизор
                ||
                role.id === 20 // Территориальный управляющий
            ))
                return true
            return false
        },
        isBuh(state) {
            if (state.user.roles.find(role =>
                role.id === 12 // Управление сетью
                ||
                role.id === 1 // Ставрополь бухгалтер
                ||
                role.id === 10 // Бухгалтер
                ||
                role.id === 8 // Абинск бухгалтер
                ||
                role.id === 16 // Ревизор
                ||
                role.id === 20 // Территориальный управляющий
            ))
                return true
            return false
        },
        canSeeKLN(state, getters) {
            if (state.user.roles.find(role =>
                role.id === 12 // Управление сетью
                ||
                role.id === 20 // Территориальный управляющий
                ||
                role.id === 3 // Управляющий
                ||
                role.id === 4 // Менеджер
                ||
                role.id === 27 //совет директоров
            ) || getters.isAdmin)
                return true
            return false
        },
        canChangePolls(state, getters) {
            if (state.user.roles.find(role =>
                role.id === 11
            ) || getters.isAdmin)
                return true
            return false
        },
        canSeePollsResults(state, getters) {
            if (state.user.roles.find(role =>
                role.id === 3
            ) || getters.isAdmin
              || state.user.id == 1062)
                return true
            return false
        },
        isLinear(state) {
            const allowedIds = [
                5, // Кассир
                6, // Пиццамейкер
                7, // Курьер
                22, // Наставник
                23, // Клинер
                24, // Тестомейкер
                25, // Универсал
                26 // Инструктор
            ];
            return state.user.roles.every(role => allowedIds.includes(role.id));
        },
    },
    mutations: {
        set_authenticated(state, value) {
            state.authenticated = value
        },
        set_user(state, value) {
            state.user = value
        },
        set_userSurveyDialog(state, value) {
            state.userSurveyDialog = value
        },
        set_loading(state, value) {
            state.loading = value
        },
        auth(state, user) {
            if (typeof user.id == 'number') {
                state.authenticated = true
                state.user = user
                if (vm.$router.currentRoute.name == 'Register' || vm.$router.currentRoute.name == 'Login')
                    vm.$router.push({name: 'Dashboard'}).catch(() => {
                    })
            } else {
                state.authenticated = false
                state.user = null
                if (vm.$router.currentRoute.name != 'Register' && vm.$router.currentRoute.name != 'Login')
                    vm.$router.push({name: 'Login'}).catch(() => {
                    })
            }
        },
        setLastUpdated(state, value) {
            state.lastUpdated = value
        },
        setUsersList(state, value) {
            state.userList = value
        },
        setRolesList(state, value) {
            state.rolesList = value
        },
        setGroupsList(state, value) {
            state.groupsList = value
        },
        setPizzeriasList(state, value) {
            state.pizzeriasList = value
        },
        setCategoriesList(state, value) {
            state.categoriesList = value
        },
        setContractorsList(state, value) {
            state.contractorsList = value
        },
    },
    actions: {
        loading({commit}, value = true) {
            commit('set_loading', value)
        },
        setUserSurveyDialog({commit}, value) {
            commit('set_userSurveyDialog', value)
        },
        async me({commit}) {
            let user = await UsersResource.me()
            commit('auth', user)
            return user
        },
        async login({commit, dispatch}, credentials) {
            await UsersResource.login(credentials)
            return dispatch('me')
        },
        async autoLogin({commit, dispatch}) {

            let redirect = await UsersResource.autoLoginRequest()

            if (redirect.to) {
                if (redirect.to.startsWith('https://lib.lookin') || redirect.to.startsWith('https://kln.lookin')) {
                    window.location.replace(redirect.to);
                } else {
                    vm.$router.replace(redirect.to);
                }
            }

            return dispatch('me')
        },
        async loginAs({commit, dispatch}, id) {
            await UsersResource.loginAs({id: id})
            return dispatch('me')
        },
        async loginAsBack({commit, dispatch}) {
            await UsersResource.loginAsBack()
            return dispatch('me')
        },
        async logout({commit, dispatch}) {
            await UsersResource.logout()
            return dispatch('me')
        },
        async updateLists({ commit, dispatch, getters}) {
            await Promise.all([
                dispatch('loadUsersList'),
                dispatch('loadGroupsList'),
                dispatch('loadRolesList'),
                dispatch('loadPizzeriasList'),
                dispatch('loadCategoriesList')
            ])
            if(getters.isAdmin || getters.isFinance) {
               await dispatch('loadContractorsList')
            }
            let time = Date.now();
            commit('setLastUpdated', time)
            localStorage.setItem('lastUpdated', time)
        },
        async loadUsersList({commit}) {
            try {
                let users = await UsersResource.list({
                    active: 1,
                    all: true,
                    order_by: 'surname',
                    compact: true,
                })
                commit('setUsersList', users.data) // Сохраняем данные в сторе
                localStorage.setItem('usersList', JSON.stringify(users.data)) // Сохраняем данные в localStorage
            } catch (e) {
                notify.show({message: e.message, color: 'error'})
            }
        },
        async loadPizzeriasList({commit}) {
            try {
                let {data} = await PizzeriasResource.list({all: true})
                commit('setPizzeriasList', data) // Сохраняем данные в сторе
                localStorage.setItem('pizzeriasList', JSON.stringify(data)) // Сохраняем данные в localStorage
            } catch (e) {
                notify.show({message: e.message, color: 'error'})
            }
        },
        async loadGroupsList({commit}) {
            try {
                let {data, meta} = await UsersResource.get('/api/groups')
                // Убираем '[Не указано]' если он уже присутствует
                data = data.filter(item => item !== '[Не указано]')
                commit('setGroupsList', data) // Сохраняем данные в сторе
                localStorage.setItem('groupsList', JSON.stringify(data)) // Сохраняем данные в localStorage
            } catch (e) {
                notify.show({message: e.message, color: 'error'})
            }
        },
        async loadRolesList({commit}) {
            try {
                let {data, meta} = await UsersResource.get('/api/roles')
                // Убираем '[Не указано]' если он уже присутствует
                data = data.filter(item => item != '[Не указано]')
                commit('setRolesList', data) // Сохраняем данные в сторе
                localStorage.setItem('rolesList', JSON.stringify(data)) // Сохраняем данные в localStorage
            } catch (e) {
                notify.show({message: e.message, color: 'error'})
            }
        },
        async loadCategoriesList({commit, state}) {
            try {
                let categories = await CategoriesResource.list({active: 1, roles: state.user.roles})
                commit('setCategoriesList', categories.data) // Сохраняем данные в сторе
                localStorage.setItem('categoriesList', JSON.stringify(categories.data)) // Сохраняем данные в localStorage
            } catch (e) {
                notify.show({message: e.message, color: 'error'})
            }
        },
        async loadContractorsList({commit, state}) {
            try {
                const {data} = await ContractorsResource.list({full: true})
                commit('setContractorsList', data) // Сохраняем данные в сторе
                localStorage.setItem('contractorsList', JSON.stringify(data)) // Сохраняем данные в localStorage

            } catch (e) {
                notify.show({message: 'ошибка', color: 'error'})
            }
        }
    },
})
